<template>
  <v-container grid-list>
    <v-layout>
      <v-flex xs6>
        <v-card>
          <v-form @submit.prevent="updateInstance">
            <v-card-title primary-title>
              <h3 class="headline mb-0">Dados pessoais</h3>
            </v-card-title>
            <v-card-text>
              <v-text-field
                label="Nome"
                v-model="Instance.Name"
                @input="$v.Instance.Name.$touch()"
                @blur="$v.Instance.Name.$touch()"
              ></v-text-field>
              <v-text-field
                label="Email"
                v-model="Instance.Email"
                @input="$v.Instance.Email.$touch()"
                @blur="$v.Instance.Email.$touch()"
              ></v-text-field>
              <kendo-upload
                ref="upload"
                :multiple="false"
                name="file"
                :validation-allowed-extensions="['.png', '.jpg', '.jpeg']"
                :select="onSelectFile"
              ></kendo-upload>
            </v-card-text>
            <v-card-actions>
              <v-btn
                class="ml-auto"
                :disabled="!isInstanceValid"
                :loading="isLoading"
                color="accent"
                type="submit"
                >Alterar</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import * as types from "@/store/types";
import { required, email } from "vuelidate/lib/validators";
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
import usersService from "@/services/api/usersService";
import UploadHelper from "@/utilities/upload";
export default {
  data() {
    return {
      isLoading: false,
      Instance: {},
    };
  },
  validations: {
    Instance: {
      Name: {
        required,
      },
      Email: {
        email,
      },
    },
  },
  computed: {
    ...mapGetters({ user: types.GET_USER }),
    isInstanceValid() {
      return !this.$v.Instance.$error;
    },
  },
  methods: {
    onSelectFile(evt) {
      UploadHelper.LoadFile(evt)
        .then((file) => (this.Instance.Photo = file))
        .catch((file) => (this.Instance.Photo = file));
    },
    ...mapMutations({ setUser: types.MUTATE_USER_INJECT }),
    async updateInstance() {
      this.$v.$touch();
      if (this.isInstanceValid) {
        if (!this.isLoading) {
          this.isLoading = true;
          await usersService
            .UpdateData(this.Instance)
            .then((response) => {
              const data = response.data;
              notificationServiceBus.showSuccessMessage(
                "Successo!",
                "Editou os seus dados com sucesso!"
              );
              this.isLoading = false;
              this.$v.$reset();
              this.Instance = {
                ...data,
                File: { Name: "", Base64Data: "", Length: 0 },
              };
              this.setUser(this.Instance);
            })
            .catch((error) => {
              this.isLoading = false;
              notificationServiceBus.showError(error.response);
            });
        }
      }
    },
  },
  created() {
    this.Instance = {
      ...this.user,
      Photo: null,
    };
  },
};
</script>

<style></style>
