const GridHelper = {
  ConvertOptionsFromNativeKendoUIGrid(pagging, filter, sort) {
    let result = {
      PageId: pagging.pageId,
      PageSize: pagging.pageSize,
      Filters: [],
      Order: [],
    };
    let index = 0;
    if (sort) {
      for (index = 0; index < sort.length; index++) {
        const s = sort[index];
        result.Order.push({
          Field: s.field,
          Direction: s.dir,
        });
      }
    }
    if (filter && filter.filters) {
      for (index = 0; index < filter.filters.length; index++) {
        const s = filter.filters[index];
        result.Filters.push({
          Field: s.field,
          Operation: s.operator,
          Value: s.value,
        });
      }
    }
    return result;
  },
  ParseOptions: function (options, appendFilters = []) {
    let result = {
      Filters: [],
      Order: [],
    };
    if (options.data) {
      var i = 0;
      result = {
        ...result,
        ...{
          PageId: options.data.page,
          PageSize: options.data.pageSize,
          Filters: appendFilters,
        },
      };
      if (options.data.filter && options.data.filter.filters) {
        for (i = 0; i < options.data.filter.filters.length; i++) {
          const item = options.data.filter.filters[i];
          result.Filters.push({
            Field: item.field,
            Operation: item.operator,
            Value: item.value,
          });
        }
      }
      if (options.data.sort) {
        for (i = 0; i < options.data.sort.length; i++) {
          const item = options.data.sort[i];
          result.Order.push({
            Field: item.field,
            Direction: item.dir.toString().toUpperCase(),
          });
        }
      }
    }
    return result;
  },
  CustomOptions: {
    Filterable: {
      mode: "menu",
      extra: false,
    },
    Export: {
      Pdf: {
        paperSize: "A4",
        repeatHeaders: true,
        avoidLinks: true,
        allPages: true,
        landscape: true,
        scale: 0.8,
        margin: { top: "1cm", left: "1cm", right: "1cm", bottom: "1cm" },
      },
    },
  },
};

export default GridHelper;
