import axios from "axios";
import { API_URL } from "@/settings";
import * as types from "@/store/types";

const instance = axios.create();

const baseUrl = API_URL + "api/statistics";

const endPoints = {
  Dashboard: baseUrl + "/dashboard",
};

// eslint-disable-next-line prettier/prettier
instance.defaults.headers.common["Authorization"] = `Bearer ${window.localStorage.getItem(types.GLOBAL_USER_TOKEN)}`;
instance.defaults.headers.common["Content-Type"] = "application/json";

const actions = {
  Dashboard() {
    return instance.get(endPoints.Dashboard);
  },
};

export default actions;
