<template>
  <v-container grid-list>
    <v-layout>
      <v-flex xs6>
        <v-card>
          <v-card-title primary-title>
            <h3 class="headline mb-0">Mensagens</h3>
          </v-card-title>
          <v-card-text> </v-card-text>
          <v-card-actions> </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>

<style></style>
